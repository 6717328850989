<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.planCategory"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="plansCat"
              field="select"
              name="plansCat"
              :clearable="false"
              :options="plans"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <!-- arabicName  -->
          <g-field
            :value.sync="selectedItem.arabicName"
            rules="required"
            label-text="arabicName"
          />
        </b-col>
        <b-col md="3">
          <g-field
            ref="englishName"
            :value.sync="selectedItem.englishName"
            label-text="englishName"
          />
        </b-col>
        <b-col md="3">
          <!-- arabicDescription  -->
          <g-field
            id="arabicDescription"
            :value.sync="selectedItem.arabicDescription"
            label-text="arabicDescription"
            name="arabicDescription"
          />
        </b-col>
        <b-col md="3">
          <!-- englishDescription  -->
          <g-field
            id="englishDescription"
            :value.sync="selectedItem.englishDescription"
            label-text="englishDescription"
            name="englishDescription"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- numberOfMonths -->
        <b-col md="3">
          <g-field
            label-text="numberOfMonths"
            field="number"
            rules="required"
            :value.sync="selectedItem.numberOfMonths"
            type="number"
          />
        </b-col>
        <!-- numberOfUsers -->
        <b-col md="3">
          <g-field
            label-text="numberOfUsers"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfUsers"
            type="number"
          />
        </b-col>
        <!-- numberOfDealers -->
        <b-col md="3">
          <g-field
            label-text="numberOfStudents"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfDealers"
            type="number"
          />
        </b-col>
        <!-- numberOfBranches -->
        <b-col md="3">
          <g-field
            label-text="numberOfBranches"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfBranches"
            type="number"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col md="3">
          <g-field
            label-text="numberOfStores"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfStores"
            type="number"
          />
        </b-col>
        <b-col md="3">
          <g-field
            label-text="numberOfItems"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfItems"
            type="number"
          />
        </b-col>
        <b-col md="3">
          <g-field
            label-text="numberOfSalesInvoices"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfSalesInvoices"
            type="number"
          />
        </b-col>
        <b-col md="3">
          <g-field
            label-text="numberOfPurchInvoices"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfPurchInvoices"
            type="number"
          />
        </b-col>
      </b-row> -->
      <b-row>
        <!-- <b-col md="3">
          <g-field
            label-text="numberOfFinancialTransactions"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfFinancialTransactions"
            type="number"
          />
        </b-col>
        <b-col md="3">
          <g-field
            label-text="numberOfVouchers"
            field="number"
            rules="required|numbersNew"
            :value.sync="selectedItem.numberOfVouchers"
            type="number"
          />
        </b-col> -->
        <!-- price -->
        <b-col md="3">
          <g-field
            label-text="price"
            field="number"
            rules="required"
            :value.sync="selectedItem.price"
            type="number"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { plans } from '@/libs/acl/Lookups';

export default {
  props: ['id'],
  data() {
    return {
      selectedItem: {
        arabicName: '',
        englishName: '',
        arabicDescription: '',
        englishDescription: '',
        price: 0,
        numberOfMonths: 0,
        numberOfUsers: 0,
        numberOfDealers: 0,
        numberOfBranches: 0,
        numberOfStores: 0,
        numberOfItems: 0,
        numberOfSalesInvoices: 0,
        numberOfPurchInvoices: 0,
        numberOfFinancialTransactions: 0,
        numberOfVouchers: 0,
      },
      plans: plans,
    };
  },
  computed: {},
  mounted() {
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.get({ url: 'Plans', id: this.id }).then((data) => {
        this.selectedItem = data;
      });
    },
    save() {
      this.create({
        url: 'Plans',
        data: this.selectedItem,
      }).then((data) => {
        this.doneAlert({
          text: this.$t('savedSuccessfully'),
        });
        this.$router.push({ name: 'plans' });
      });
    },
  },
};
</script>

<style></style>
